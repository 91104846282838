import React from "react"
import GalleriTest from "../components/bilder/GalleriTest"
import Layout from "../components/layout"
export default function TestPage() {
  return (
    <Layout>
      <GalleriTest />
    </Layout>
  )
}

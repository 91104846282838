import Branding from "./common/Branding"
import { Link } from "gatsby"
import { Spiral as Hamburger } from "hamburger-react"
import React, { useState, useLayoutEffect } from "react"

export default function Header() {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <>
      <div className=" md:h-screen px-2 py-6  bg-sand md:sticky md:top-0 flex flex-row md:flex-col items-start justify-between md:justify-center  md:px-10 ">
        <div className="md:pb-12 relative">
          <Branding />
        </div>
        <div className="md:space-y-4 text-xs md:text-sm uppercase tracking-wider hidden md:flex flex-row md:flex-col space-x-2 md:space-x-0">
          {mobileMenu.items.map(item => {
            return (
              <div key={item.route} className="">
                <Link
                  to={item.route}
                  activeClassName="font-bold text-gray-900"
                  className=" text-xs md:text-sm uppercase tracking-wider"
                >
                  {item.title}
                </Link>
              </div>
            )
          })}
        </div>
        <div className="pt-4 hidden md:block">
          <div className="w-12">
            <a href="mailto:info@jensnordstrom.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="block md:hidden z-40 relative">
          <Hamburger
            toggled={isExpanded}
            toggle={toggleExpansion}
            className="z-40 relative"
          />
        </div>
        {isExpanded && <MobileMenu />}
      </div>
    </>
  )
}

const mobileMenu = {
  items: [
    { title: "Porträtt & människor", route: "/" },
    { title: "Mat & dryck", route: "/mat/" },
    { title: "Stilleben & platser", route: "/stilleben/" },
    { title: "Film & Video", route: "/video/" },
  ],
}

function MobileMenu() {
  useLockBodyScroll()

  return (
    <div className="fixed top-0 left-0 overflow-x-hidden z-30 bg-white inset-0 h-screen md:hidden">
      <div className="relative h-full     text-black  ">
        <div className="pt-2 pb-6 px-5 h-full  flex flex-col items-center justify-center text-2xl md:text-3xl uppercase font-bold">
          <div>
            <div className=" space-y-8 max-w-xl w-full mx-auto flex flex-col">
              {mobileMenu.items.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.route}
                    activeClassName="font-bold text-gray-300"
                  >
                    {item.title}
                  </Link>
                )
              })}
              <div className="w-12">
                <a href="mailto:info@jensnordstrom.com">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, []) // Empty array ensures effect is only run on mount and unmount
}

import React, { useState } from "react"
import ThumbNails from "./Thumbnails"
import { graphql, StaticQuery } from "gatsby"
import Lightbox from "./Lightbox"
import "../../styles/lightbox.css"

export default function GalleriTest() {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = i => () => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => () => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => () => {
    setSelectedImage((i + 1) % length)
  }
  return (
    <div>
      {" "}
      <StaticQuery
        query={graphql`
          {
            wpPage(databaseId: { eq: 494 }) {
              id
              bilder {
                bild {
                  caption
                  bildfil {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(quality: 70)
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const images = data.wpPage.bilder.bild
          return (
            <div className="">
              <ThumbNails images={images} handleOpen={handleOpen} />

              {showLightbox && selectedImage !== null && (
                <Lightbox
                  images={images}
                  handleClose={handleClose}
                  handleNextRequest={handleNextRequest}
                  handlePrevRequest={handlePrevRequest}
                  selectedImage={selectedImage}
                />
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

import React from "react";
import { Link } from "gatsby";
import Logo from "./Logo";
function Branding() {
  return (
    <div className="w-48">
      <Link to="/">
        <Logo />
      </Link>
    </div>
  );
}

export default Branding;

import PropTypes from "prop-types"
import React from "react"

import Header from "./header"

function Layout({ children }) {
  return (
    <div className="flex flex-col md:flex-row text-gray-900 bg-white">
      <Header />
      <div className="flex-1">
        <main className="h-full md:p-2">{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
